import UAParser from 'ua-parser-js';
const appInstalledUserAgents = [
  'com.menupro.app',
  'com.example.menupro',
];
export const handleDeepLinkRedirection = (deepLinkUrl) => {
  const parser = new UAParser(window.navigator.userAgent);
  const isMobileDevice = parser.getDevice().type === 'mobile';

  if (!isMobileDevice) {
    console.log('This link is intended for mobile devices.');
    return true; // Indicates web environment
  }

  const osName = parser.getOS().name;
  const appStoreUrls = {
    'Android': 'https://play.google.com/store/apps/details?id=com.menupro.app',
    'iOS': 'https://apps.apple.com/tn/app/menupro/id6464469617'
  };

  try {
    // Attempt to open app directly
    window.location.href = deepLinkUrl;

    // Add a short timeout to check if app opened
    setTimeout(() => {
      window.location.href = appStoreUrls[osName] || appStoreUrls['Android'];
    }, 2000); // Adjust timeout as needed
  } catch (error) {
    // Fallback to app store
    window.location.href = appStoreUrls[osName] || appStoreUrls['Android'];
  }

  return false;
};